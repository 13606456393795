import QRCode from "qrcode";
import { Button, Modal, NumberInput, Stack } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useCallback, useEffect, useRef, useState } from "react";

export default function QRCodeModal(props: { onClose: () => void, open: boolean, url: string }) {
    const [size, setSize] = useState(1000);
    const canvasRef = useRef<HTMLCanvasElement>();

    const generate = useCallback(() => {
        if (!props.open || !props.url) return;

        QRCode.toCanvas(canvasRef.current, props.url, {
            width: 300
        }, function (error) {
            if (!error) return;

            showNotification({
                title: "Error",
                message: "URL is too big!",
                color: 'red',
                autoClose: 5000,
                style: {
                    width: '300px'
                }
            });
        });
    }, [props.url, props.open]);

    async function download() {
        QRCode.toDataURL(props.url, {
            width: size
        }, function (_, url) {
            const link = document.createElement('a');
            link.download = 'qr-code.png';
            link.href = url
            link.click();
        });
    }

    const canvasRefCallback = useCallback((node: HTMLCanvasElement) => {
        canvasRef.current = node;
        if (node) {
            generate();
        }
    }, [generate]);

    useEffect(() => {
        generate();
    }, [generate]);

    return (
        <Modal title="QR Code" size="auto" padding="xl" onClose={props.onClose} opened={props.open}>
            <Stack gap="sm">
                <canvas ref={canvasRefCallback} width={300} height={300} />
                <Stack gap="xs">
                    <NumberInput
                        label="Size"
                        value={size}
                        onChange={(value) => setSize(Number(value))}
                        min={100}
                        suffix="px"
                    />
                    <Button variant="filled" color="blue" onClick={download}>Download</Button>
                </Stack>
            </Stack>
        </Modal>
    );
}