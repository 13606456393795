import App from "./App";
import AuthorizationProvider, { AuthTemplate, UnauthTemplate } from "./Context/AuthorizationProvider";
import ConfirmEmailCode from "./Pages/ConfirmEmailCode";
import dayjs from "dayjs";
import Landing from "./Pages/Landing";
import localizedFormat from "dayjs/plugin/localizedFormat";
import Login from "./Pages/Login";
import ReactDOM from "react-dom/client";
import resources from "./Shared/resources.json";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { createTheme, MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
dayjs.extend(localizedFormat);

const theme = createTheme({

});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <MantineProvider theme={theme} defaultColorScheme="dark">
            <ModalsProvider>
                <Notifications />
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route
                        path="/*"
                        element={
                            <AuthorizationProvider clientId={resources.CognitoUserPoolClientId} userPoolId={resources.CognitoUserPoolId} apiEndpoint={resources.APIEndpoint}>
                                <UnauthTemplate>
                                    <Routes>
                                        <Route path="/" element={<Navigate to="/login" />} />
                                        <Route path="/login" element={<Login mode="SignIn" />} />
                                        <Route path="/create-account" element={<Login mode="CreateAccount" />} />
                                        <Route path="/confirm-registration" element={<ConfirmEmailCode />} />
                                        <Route path="/*" element={<Navigate to="/login" />} />
                                    </Routes>
                                </UnauthTemplate>
                                <AuthTemplate>
                                    <App />
                                </AuthTemplate>
                            </AuthorizationProvider>
                        }
                    />
                </Routes>
            </ModalsProvider>
        </MantineProvider>
    </BrowserRouter>
);