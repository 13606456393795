import * as Yup from "yup";
import LoginContainer from "../Components/LoginContainer/LoginContainer";
import {
    Button,
    PasswordInput,
    Stack,
    Text,
    TextInput
} from "@mantine/core";
import { Formik } from "formik";
import { showNotification } from "@mantine/notifications";
import { useAuthorization } from "../Context/AuthorizationProvider";
import { useNavigate } from "react-router";
import { useState } from "react";

type Mode = "SignIn" | "CreateAccount";

export default function Auth({ mode }: { mode: Mode }) {
    const { auth } = useAuthorization();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    return (
        <LoginContainer>
            <Formik<{ email: string, password: string, confirmPassword: string }>
                initialValues={{ email: "", password: "", confirmPassword: "" }}
                onSubmit={async (values) => {
                    setLoading(true);
                    if (mode === "SignIn") {
                        const success = await auth.login(values.email, values.password);
                        if (!success) {
                            showNotification({ title: "Login failed", message: "Invalid email or password", color: "red" });
                        }
                    } else {
                        await auth.signup(values.email, values.password);
                        navigate(`/confirm-registration?${new URLSearchParams({ email: values.email }).toString()}`);
                    }
                    setLoading(false);
                }}
                validateOnChange
                validateOnBlur
                validationSchema={Yup.object({
                    email: Yup.string().email("Invalid email address").required("Email is required"),
                    password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
                    confirmPassword: Yup.string().when("mode", {
                        is: "CreateAccount",
                        then: schema => schema.oneOf([Yup.ref("password")], "Passwords must match").required("Confirm Password is required"),
                        otherwise: schema => schema.notRequired(),
                    }),
                })}
            >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <Stack gap="md">
                            <TextInput
                                value={formikProps.values.email}
                                onChange={formikProps.handleChange}
                                label="Email"
                                placeholder="Enter your email"
                                type="email"
                                name="email"
                                error={formikProps.touched.email && formikProps.errors.email}
                                required
                                onBlur={formikProps.handleBlur}
                            />
                            <PasswordInput
                                value={formikProps.values.password}
                                onChange={formikProps.handleChange}
                                label="Password"
                                name="password"
                                placeholder="Enter your password"
                                error={formikProps.touched.password && formikProps.errors.password}
                                required
                                onBlur={formikProps.handleBlur}
                            />
                            {mode === "CreateAccount" && (
                                <PasswordInput
                                    value={formikProps.values.confirmPassword}
                                    onChange={formikProps.handleChange}
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    placeholder="Re-enter your password"
                                    error={formikProps.touched.confirmPassword && formikProps.errors.confirmPassword}
                                    required
                                    onBlur={formikProps.handleBlur}
                                />
                            )}
                            <Button
                                variant="filled"
                                fullWidth
                                loading={loading}
                                type="submit"
                                disabled={loading}
                            >
                                {mode === "SignIn" ? "Sign In" : "Create Account"}
                            </Button>
                            <Stack gap="sm" style={{ marginTop: "16px" }}>
                                <Text size="sm">{mode === "SignIn" ? "Don't have an account?" : "Already have an account?"}</Text>
                                <Button
                                    variant="filled"
                                    fullWidth
                                    disabled={loading}
                                    loading={loading}
                                    onClick={() => navigate(mode === "CreateAccount" ? "/login" : "/create-account")}>
                                    {mode === "SignIn" ? "Create Account" : "Login"}
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
        </LoginContainer>
    );
}