export function getHost() {
    const host = window.location.host;
    if (/dev-manage/ig.test(host)) {
        return 'dev.bigurl.io';
    } else if (/localhost/ig.test(host)) {
        return 'localhost:3000';
    } else {
        return 'bigurl.io';
    }
}

export function buildRedirectUrl(pathname: string, withProtocol = false) {
    const host = getHost();
    return `${withProtocol ? "https://" : ""}${host}/${pathname}`;
}