import {
    Box,
    Button,
    Code,
    Group,
    Stack,
    Text,
    Timeline,
    Title
} from "@mantine/core";
import {
    IconExternalLink,
    IconLogin,
    IconRouteSquare,
    IconShare,
    IconUserCheck,
    IconWorldWww
} from "@tabler/icons-react";
import { IS_PROD } from "../Shared/constants";

const CREATE_ACCOUNT = IS_PROD ? "https://manage.bigurl.io/create-account" : "https://dev-manage.bigurl.io/create-account";
const LOGIN = IS_PROD ? "https://manage.bigurl.io/login" : "https://dev-manage.bigurl.io/login";

export default function Landing() {
    function handleCreateAccount() {
        window.location.href = CREATE_ACCOUNT;
    }

    function handleLogin() {
        window.location.href = LOGIN;
    }
    return (
        <Box style={{ padding: "8px"}}>
            <Group justify="flex-end">
                <Button onClick={handleCreateAccount} variant="filled" color="blue" size="lg">Create an account</Button>
                <Button onClick={handleLogin} variant="filled" color="blue" size="lg">Log in</Button>
            </Group>
            <Stack gap="120px" align="center" justify="center" style={{ padding: "32px 16px" }}>
                <Stack>
                    <Title order={1}>Welcome to BigURL.io!</Title>
                    <Text size="xl">A site that shortens your big URLs for free.</Text>
                </Stack>
                <Stack align="center">
                    <Box style={theme => ({ backgroundColor: theme.colors.grape[5], padding: "24px", borderRadius: "500px" })}>
                        <Text c="white" size="lg">https://www.youtube.com/watch?v=dQw4w9WgXcQ</Text>
                    </Box>
                    <Text>becomes</Text>
                    <Box style={theme => ({ backgroundColor: theme.colors.blue[5], padding: "24px", borderRadius: "500px" })}>
                        <Text c="white" size="lg">bigurl.io/my-site</Text>
                    </Box>
                </Stack>
                <Stack>
                    <Title order={2}>Get Started</Title>
                    <Timeline active={6} lineWidth={6} bulletSize={40}>
                        <Timeline.Item color="blue" bullet={<IconUserCheck />} title="Create an account">
                            <Button onClick={handleCreateAccount} variant="outline" color="blue" size="compact-sm">Click here to create an account</Button>
                        </Timeline.Item>
                        <Timeline.Item color="blue" bullet={<IconLogin />} title="Log in">
                            <Button onClick={handleLogin} variant="outline" color="blue" size="compact-sm">Click here to log in</Button>
                        </Timeline.Item>
                        <Timeline.Item color="blue" bullet={<IconRouteSquare />} title="Reserve a path">
                            <Text size="sm" c="dimmed">For example, <Code>bigurl.io/your-path</Code></Text>
                        </Timeline.Item>
                        <Timeline.Item color="blue" bullet={<IconWorldWww />} title="Specify your big URL">
                            <Text size="sm" c="dimmed">For example, <Code>https://www.google.com/</Code></Text>
                        </Timeline.Item>
                        <Timeline.Item color="blue" bullet={<IconExternalLink />} title="Get your shortened URL">
                            <Text size="sm" c="dimmed">From our example above, our shortened URL will be <Code>bigurl.io/your-path</Code></Text>
                        </Timeline.Item>
                        <Timeline.Item color="blue" bullet={<IconShare />} title="Share your shortened URL">
                            <Text size="sm" c="dimmed">Anyone who visits your shortened URL will be redirected to your big URL!</Text>
                        </Timeline.Item>
                    </Timeline>
                </Stack>
            </Stack>
        </Box>
    );
}