import LoginContainer from "../Components/LoginContainer/LoginContainer";
import { Button, Stack, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useAuthorization } from "../Context/AuthorizationProvider";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ConfirmEmailCode() {
    const { auth } = useAuthorization();
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [params] = useSearchParams();

    useEffect(() => {
        if (!params.has("email")) navigate("/login");
    }, [params, navigate]);

    async function checkCode() {
        setLoading(true);
        const result = await auth.confirmRegistration(code, params.get("email")!);
        if (result) {
            showNotification({ title: "Account confirmed", message: "Your account has been confirmed", color: "green" });
            navigate("/login");
        } else {
            showNotification({ title: "Invalid code", message: "The code you entered is incorrect", color: "red" });
        }

        setLoading(false);
    }

    return (
        <LoginContainer>
            <Stack gap="md">
                <TextInput
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    label="Confirmation Code"
                    placeholder="Enter your confirmation code"
                    type="text"
                    name="code"
                    required
                />
                <Button loading={loading} onClick={checkCode} variant="filled">Confirm</Button>
            </Stack>
        </LoginContainer>
    );
}