import classes from "./LoginContainer.module.css";
import React from "react";
import { Box, Card } from "@mantine/core";

export default function LoginContainer(props: { children: React.ReactNode }) {
    return (
        <Box className={classes.root}>
            <Box className={classes.inner}>
                <Card padding="md" radius="md" withBorder className={classes.card}>
                    {props.children}
                </Card>
            </Box>
        </Box>
    );
}