import * as Yup from "yup";
import _ from "lodash";
import dayjs from "dayjs";
import QRCodeModal from "../Components/QRCodeModal";
import {
    ActionIcon,
    Anchor,
    Button,
    Card,
    CopyButton,
    Group,
    Loader,
    Pill,
    Stack,
    Table,
    Text,
    Textarea,
    Tooltip
} from "@mantine/core";
import { APITypes } from "../API/APITypes";
import { buildRedirectUrl } from "../Utils/host";
import { Formik, FormikProps } from "formik";
import {
    IconCheck,
    IconCopy,
    IconDeviceFloppy,
    IconPencilCancel,
    IconQrcode,
    IconTrashFilled
} from "@tabler/icons-react";
import { MAX_REDIRECTS_PER_USER } from "../Shared/constants";
import { modals } from "@mantine/modals";
import { pathNameRegexString, Redirect } from "../Models/Redirect";
import { showNotification } from "@mantine/notifications";
import { useAuthorization } from "../Context/AuthorizationProvider";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type RedirectWithEditing = Redirect & { editing: "new" | boolean, loading: boolean };

export default function Redirects() {
    const { database } = useAuthorization();
    const queryCLient = useQueryClient();
    const [selectedRedirectForQrCode, setSelectedRedirectForQrCode] = useState<string | null>(null);
    const query = useQuery({
        queryKey: ["redirects"],
        queryFn: () => database.listRedirects(),
    });
    const createRedirectMutation = useMutation({
        mutationFn: (variables: APITypes.Database.CreateRedirectParams) => database.createRedirect(variables),
        onMutate: async () => {
            setData(data => {
                const newRedirect = data.find(x => x.editing === "new");
                if (!newRedirect) return data;
                newRedirect.loading = true;
                return [...data];
            });
        },
        onSuccess: async (data) => {
            if (data === "too many") {
                showNotification({
                    title: "Limit Reached",
                    message: `You have reached the limit of ${MAX_REDIRECTS_PER_USER} redirects.`,
                    color: "red",
                });

                return;
            }

            await queryCLient.invalidateQueries({
                queryKey: ["redirects"]
            });
        }
    });
    const deleteRedirectMutation = useMutation({
        mutationFn: (params: APITypes.Database.DeleteRedirectParams) => database.deleteRedirect(params),
        onMutate: async (params) => {
            setData(data => {
                const row = data.find(x => x.pathname === params.pathname);
                if (!row) return data;
                row.loading = true;
                return [...data];
            });
        },
        onSuccess: async (_, variables) => {
            setData(data => data.filter(x => x.pathname !== variables.pathname));
            await queryCLient.invalidateQueries({
                queryKey: ["redirects"]
            });
        }
    });
    const [data, setData] = useState<RedirectWithEditing[]>([]);

    useEffect(() => {
        if (!query.data) return;

        setData(_.orderBy(query.data, x => x.createdAt, "desc").map(x => ({ ...x, editing: false, loading: false })));
    }, [query.data]);

    function addNewRedirect() {
        setData(data => [{ createdAt: "", pathname: "", redirectTo: "", owner: "", updatedAt: "", editing: "new", loading: false }, ...data]);
    }

    function cancelEdit(row: RedirectWithEditing) {
        setData(data => data.filter(x => x.editing !== "new"));
    }

    async function saveRedirect(original: RedirectWithEditing, row: RedirectWithEditing) {
        await createRedirectMutation.mutateAsync({ pathname: row.pathname, redirectTo: row.redirectTo });
    }

    async function deleteRedirect(row: RedirectWithEditing) {
        const confirm = await new Promise<boolean>(resolve => {
            modals.openConfirmModal({
                title: 'Confirm Redirect Deletion',
                children: (
                    <Text size="sm">
                        Are you sure you want to delete the redirect with path <span style={{ color: "var(--mantine-color-blue-5)" }}>{row.pathname}</span>?
                    </Text>
                ),
                labels: { confirm: 'Confirm', cancel: 'Cancel' },
                onCancel: () => resolve(false),
                onConfirm: () => resolve(true),
            });
        });

        if (!confirm) return;
        deleteRedirectMutation.mutate({ pathname: row.pathname });
    }

    if (query.isPending) return <Loader />;

    return (
        <>
            <Stack gap="md">
                <div>
                    <Button variant="filled" onClick={addNewRedirect} disabled={data.some(x => x.editing === "new") || data.length >= MAX_REDIRECTS_PER_USER}>Add New</Button>
                </div>
                <Stack gap="md" hiddenFrom="md">
                    {data.map((row => {
                        const editing = row.editing === "new" || row.editing;
                        const url = buildRedirectUrl(row.pathname);

                        if (editing) {
                            return (
                                <EditRow key={row.pathname} row={row} onSave={saveRedirect} onCancel={cancelEdit} mobile />
                            );
                        }

                        return (
                            <Card padding="lg" radius="md" withBorder key={row.pathname}>
                                <Stack gap="md">
                                    <Group gap="md" justify="space-between">
                                        <Text
                                            style={theme => ({
                                                backgroundColor: theme.colors.blue[7],
                                                padding: theme.spacing.sm,
                                                borderRadius: theme.radius.xl
                                            })}
                                            c="white"
                                            size="lg"
                                        >
                                            bigurl.io/{row.pathname}
                                        </Text>
                                        <Group gap="sm">
                                            <CopyButton timeout={1000} value={url}>
                                                {stuff => (
                                                    <ActionIcon variant="filled" color={stuff.copied ? "green" : "blue"} onClick={() => { stuff.copy(); showNotification({ title: "Copied", message: "Pathname copied to clipboard!", color: "green" }); }} loading={row.loading} disabled={row.loading}>
                                                        {stuff.copied ? <IconCheck /> : <IconCopy />}
                                                    </ActionIcon>
                                                )}
                                            </CopyButton>
                                            <Tooltip label="Generate QR Code">
                                                <ActionIcon variant="filled" color="blue" onClick={() => setSelectedRedirectForQrCode(buildRedirectUrl(row.pathname, true))} loading={row.loading} disabled={row.loading}>
                                                    <IconQrcode />
                                                </ActionIcon>
                                            </Tooltip>
                                            <Tooltip label="Delete Redirect">
                                                <ActionIcon variant="filled" color="red" onClick={() => deleteRedirect(row)} loading={row.loading} disabled={row.loading}>
                                                    <IconTrashFilled />
                                                </ActionIcon>
                                            </Tooltip>
                                        </Group>
                                    </Group>
                                    <Anchor href={row.redirectTo}>{row.redirectTo}</Anchor>
                                    <Text size="sm">Created: {dayjs(row.createdAt).format("lll")}</Text>
                                </Stack>
                            </Card>
                        );
                    }))}
                </Stack>
                <Table striped visibleFrom="md">
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Path</Table.Th>
                            <Table.Th w="45%">Destination</Table.Th>
                            <Table.Th w="10%">Created</Table.Th>
                            <Table.Th w="10%">Actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {data.map((row) => {
                            const editing = row.editing === "new" || row.editing;
                            const url = buildRedirectUrl(row.pathname);

                            if (editing) {
                                return (
                                    <EditRow key={row.pathname} row={row} onSave={saveRedirect} onCancel={cancelEdit} mobile={false} />
                                );
                            }
                            return (
                                <Table.Tr key={row.pathname}>
                                    <Table.Td>bigurl.io/{row.pathname}</Table.Td>
                                    <Table.Td style={{ wordWrap: "break-word", whiteSpace: "normal" }}><Anchor href={row.redirectTo}>{row.redirectTo}</Anchor></Table.Td>
                                    <Table.Td>{dayjs(row.createdAt).format("lll")}</Table.Td>
                                    <Table.Td>
                                        <Group gap="sm">
                                            <CopyButton timeout={1000} value={buildRedirectUrl(row.pathname)}>
                                                {stuff => (
                                                    <Tooltip label="Copy Redirect">
                                                        <ActionIcon variant="filled" color={stuff.copied ? "green" : "blue"} onClick={() => { stuff.copy(); showNotification({ title: "Copied", message: "Pathname copied to clipboard!", color: "green" }); }} loading={row.loading} disabled={row.loading}>
                                                            {stuff.copied ? <IconCheck /> : <IconCopy />}
                                                        </ActionIcon>
                                                    </Tooltip>
                                                )}
                                            </CopyButton>
                                            <Tooltip label="Generate QR Code">
                                                <ActionIcon variant="filled" color="blue" onClick={() => setSelectedRedirectForQrCode(buildRedirectUrl(row.pathname, true))} loading={row.loading} disabled={row.loading}>
                                                    <IconQrcode />
                                                </ActionIcon>
                                            </Tooltip>
                                            <Tooltip label="Delete Redirect">
                                                <ActionIcon variant="filled" color="red" onClick={() => deleteRedirect(row)} loading={row.loading} disabled={row.loading}>
                                                    <IconTrashFilled />
                                                </ActionIcon>
                                            </Tooltip>
                                        </Group>
                                    </Table.Td>
                                </Table.Tr>
                            );
                        })}
                    </Table.Tbody>
                </Table>
            </Stack>
            <QRCodeModal open={Boolean(selectedRedirectForQrCode)} onClose={() => setSelectedRedirectForQrCode(null)} url={selectedRedirectForQrCode ?? ""} />
        </>
    );
}

function EditRow(props: { row: RedirectWithEditing, onSave: (original: RedirectWithEditing, row: RedirectWithEditing) => void, onCancel: (original: RedirectWithEditing) => void, mobile: boolean }) {
    const [checkingAvailability, setCheckingAvailability] = useState(false);
    const [availableCheckedFirstTime, setAvailableCheckedFirstTime] = useState(false);
    const [available, setAvailable] = useState(false);
    const { database } = useAuthorization();

    async function checkAvailability(pathname: string) {
        setCheckingAvailability(true);
        const available = await database.checkPathnameAvailability({ pathname });
        setAvailable(available);
        setCheckingAvailability(false);
        setAvailableCheckedFirstTime(true);
    }

    return (
        <Formik
            initialValues={{ pathname: props.row.pathname as string, redirectTo: props.row.redirectTo as string }}
            onSubmit={async (values) => {
                props.onSave(props.row, { ...props.row, pathname: values.pathname, redirectTo: values.redirectTo });
            }}
            validateOnChange
            validateOnBlur
            validationSchema={Yup.object({
                pathname: Yup.string().required("You must enter a path name").matches(new RegExp(pathNameRegexString), "Invalid pathname"),
                redirectTo: Yup.string().required("You must enter a destination URL").url("Invalid URL"),
            })}
        >
            {formikProps => (
                <>
                    {props.mobile &&
                        <Card padding="lg" radius="md" withBorder>
                            <Stack gap="md">
                                <Group gap="md" justify="space-between">
                                    <PathnameInput
                                        formikProps={formikProps}
                                        checkAvailability={checkAvailability}
                                        availableCheckedFirstTime={availableCheckedFirstTime}
                                        available={available}
                                        checkingAvailability={checkingAvailability}
                                    />
                                    <EditActions
                                        onSave={formikProps.handleSubmit}
                                        onCancel={() => props.onCancel(props.row)}
                                        checkingAvailability={checkingAvailability}
                                        available={available}
                                        row={props.row}
                                    />
                                </Group>
                                <DestinationInput formikProps={formikProps} />
                            </Stack>
                        </Card>
                    }
                    {!props.mobile &&
                        <Table.Tr>
                            <Table.Td>
                                <PathnameInput
                                    formikProps={formikProps}
                                    checkAvailability={checkAvailability}
                                    availableCheckedFirstTime={availableCheckedFirstTime}
                                    available={available}
                                    checkingAvailability={checkingAvailability}
                                />
                            </Table.Td>
                            <Table.Td>
                                <DestinationInput formikProps={formikProps} />
                            </Table.Td>
                            <Table.Td />
                            <Table.Td>
                                <EditActions
                                    onSave={() => formikProps.handleSubmit()}
                                    onCancel={() => props.onCancel(props.row)}
                                    checkingAvailability={checkingAvailability}
                                    available={available}
                                    row={props.row}
                                />
                            </Table.Td>
                        </Table.Tr>
                    }
                </>
            )}
        </Formik>
    );
}

function PathnameInput({ formikProps, checkAvailability, availableCheckedFirstTime, available, checkingAvailability }: { formikProps: FormikProps<{ pathname: string, redirectTo: string }>, checkAvailability: (pathname: string) => void, availableCheckedFirstTime: boolean, available: boolean, checkingAvailability: boolean }) {
    return (
        <Textarea
            autosize
            leftSection={<Pill color="blue">bigurl.io/</Pill>}
            leftSectionWidth="80px"
            required
            style={{ flexGrow: 1 }}
            value={formikProps.values.pathname}
            name="pathname"
            onChange={formikProps.handleChange}
            onBlur={e => {
                formikProps.handleBlur(e);
                if (formikProps.errors.pathname || !formikProps.values.pathname) return;
                checkAvailability(formikProps.values.pathname);
            }}
            error={(formikProps.touched.pathname && formikProps.errors.pathname) || (availableCheckedFirstTime && !available && "Unavailable")}
            placeholder="Enter a single path name here (e.g. my-page)"
            disabled={checkingAvailability}
            rightSection={checkingAvailability && <Loader size="xs" />}
        />
    );
}

function DestinationInput({ formikProps }: { formikProps: FormikProps<{ pathname: string, redirectTo: string }> }) {
    return (
        <Textarea
            autosize
            required
            value={formikProps.values.redirectTo}
            name="redirectTo"
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            error={formikProps.touched.redirectTo && formikProps.errors.redirectTo}
            placeholder="Enter a destination URL here (e.g. https://example.com/my-page)"
        />
    );
}

function EditActions({ onSave, onCancel, checkingAvailability, available, row }: { onSave: () => void, onCancel: (row: RedirectWithEditing) => void, checkingAvailability: boolean, available: boolean, row: RedirectWithEditing }) {
    return (
        <Group gap="md">
            <Tooltip label="Save">
                <ActionIcon variant="filled" color="blue" onClick={onSave} disabled={row.loading || checkingAvailability || !available} loading={row.loading || checkingAvailability}>
                    <IconDeviceFloppy />
                </ActionIcon>
            </Tooltip>
            <Tooltip label="Cancel">
                <ActionIcon variant="filled" color="red" onClick={() => onCancel(row)} disabled={row.loading || checkingAvailability} loading={row.loading || checkingAvailability}>
                    <IconPencilCancel />
                </ActionIcon>
            </Tooltip>
        </Group>
    );
}