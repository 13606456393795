import NavItem from "./NavItem/NavItem";
import { AppShell } from "@mantine/core";
import { IconExternalLinkOff, IconUserCircle } from "@tabler/icons-react";
import { useMatch } from "react-router";

export default function LeftNav() {
    const tab = useMatch("/:tab")?.params.tab;

    return (
        <AppShell.Navbar>
            <NavItem icon={<IconExternalLinkOff />} text="Redirects" selected={tab === "redirects"} to="/redirects" />
            <NavItem icon={<IconUserCircle />} text="Account" selected={tab === "account"} to="/account" />
        </AppShell.Navbar>
    );
}