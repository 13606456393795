import Auth from "../API/Auth";
import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useRef
} from "react";
import { DatabaseAPI } from "../API/API";
import { Loader } from "@mantine/core";

const AuthorizationContext = createContext<{ auth: Auth, database: DatabaseAPI }>(null!);

export function useAuthorization() {
    return useContext(AuthorizationContext);
}

export function UnauthTemplate(props: { children: React.ReactNode }) {
    const { auth } = useAuthorization();

    return <>{auth.isAuthenticated ? null : props.children}</>;
}

export function AuthTemplate(props: { children: React.ReactNode }) {
    const { auth } = useAuthorization();

    return <>{auth.isAuthenticated ? props.children : null}</>;
}

export default function AuthorizationProvider(props: { children: React.ReactNode, clientId: string, userPoolId: string, apiEndpoint: string }) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const auth = useRef<Auth | null>(null);
    const database = useRef<DatabaseAPI | null>(null);

    useEffect(() => {
        async function init() {
            auth.current = await Auth.create(props.userPoolId, props.clientId);
            database.current = new DatabaseAPI(props.apiEndpoint, auth.current);
            auth.current.subscribeSession(forceUpdate);
            forceUpdate();
        }

        init();

        return () => {
            auth.current?.unsubscribeSession(forceUpdate);
        }
    }, [props.clientId, props.userPoolId, props.apiEndpoint]);

    if (!auth.current || !database.current) return <Loader />;
    return (
        <AuthorizationContext.Provider value={{ auth: auth.current, database: database.current }}>
            {props.children}
        </AuthorizationContext.Provider>
    );
}