import { Stack, Text } from "@mantine/core";
import { useAuthorization } from "../Context/AuthorizationProvider";

function InfoItem(props: { label: string, value: string }) {
    return (
        <Stack gap="sm">
            <Text size="xl" c="blue">{props.label}</Text>
            <Text>{props.value}</Text>
        </Stack>
    );
}

export default function Account() {
    const { auth } = useAuthorization();
    return (
        <div>
            <Stack gap="md">
                <InfoItem label="Email" value={auth.idTokenPayload.email} />
            </Stack>
        </div>
    );
}