import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";

export default class Auth {
    private readonly _userPoolId: string = "";
    private readonly _clientId: string = "";
    private readonly _userPool: CognitoUserPool;
    private _session: CognitoUserSession | null = null;
    private _sessionSubscribers: Array<() => void> = [];

    public get accessToken(): string {
        return this._session?.getAccessToken().getJwtToken() ?? "";
    }

    public get refreshToken(): string {
        return this._session?.getRefreshToken().getToken() ?? "";
    }

    public get idToken(): string {
        return this._session?.getIdToken().getJwtToken() ?? "";
    }

    public get idTokenPayload(): Record<string, string> {
        return this._session?.getIdToken().decodePayload() ?? {};
    }

    public get isAuthenticated(): boolean {
        return this._session !== null;
    }

    private get session(): CognitoUserSession | null {
        return this._session;
    }

    private set session(value: CognitoUserSession | null) {
        this._session = value;
        this._sessionSubscribers.forEach(callback => callback());
    }

    private constructor(userPoolId: string, clientId: string) {
        this._userPoolId = userPoolId;
        this._clientId = clientId;
        this._userPool = new CognitoUserPool({
            UserPoolId: this._userPoolId,
            ClientId: this._clientId,
        });
    }

    public static async create(userPoolId: string, clientId: string): Promise<Auth> {
        const client = new Auth(userPoolId, clientId);
        await client.init();
        return client;
    }

    public async init(): Promise<void> {
        return await new Promise(resolve => {
            const user = this._userPool.getCurrentUser();
            if (user) {
                user.getSession((err: Error | null, session: CognitoUserSession) => {
                    if (err) {
                        resolve();
                    } else {
                        this.onSessionCreated(session);
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    }

    public subscribeSession(callback: () => void): void {
        this._sessionSubscribers.push(callback);
    }

    public unsubscribeSession(callback: () => void): void {
        this._sessionSubscribers = this._sessionSubscribers.filter(c => c !== callback);
    }

    private onSessionCreated(session: CognitoUserSession) {
        this.session = session;
    }

    public async login(email: string, password: string): Promise<boolean> {
        const user = new CognitoUser({
            Username: email,
            Pool: this._userPool,
        });
        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });
        return await new Promise(resolve => user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                this.onSessionCreated(data);
                resolve(true);
            },
            onFailure: (err) => {
                console.log("onFailure:", err);
                resolve(false);
            },
            newPasswordRequired: (data) => {
                resolve(false);
            },
        }));
    }

    public async signup(email: string, password: string): Promise<boolean> {
        return await new Promise(resolve => this._userPool.signUp(email, password, [], [], (err, data) => {
            if (err) {
                console.log("onFailure:", err);
                resolve(false);
            } else {
                console.log("onSuccess:", data);
                resolve(true);
            }
        }));
    }

    public async logout(): Promise<void> {
        const user = this._userPool.getCurrentUser();
        if (user) {
            user.signOut();
            this.session = null;
        }
    }

    public async confirmRegistration(code: string, email: string): Promise<boolean> {
        const user = new CognitoUser({
            Username: email,
            Pool: this._userPool,
        });

        if (user) {
            return await new Promise(resolve => user.confirmRegistration(code, true, (err, data) => {
                if (err) {
                    console.log("onFailure:", err);
                    resolve(false);
                } else {
                    console.log("onSuccess:", data);
                    resolve(true);
                }
            }));
        }
        return false;
    }
}