import classes from "./NavItem.module.css";
import clsx from "clsx";
import { Group } from "@mantine/core";
import { Link } from "react-router-dom";

export default function NavItem({ text, icon, selected = false, to }: { text: string, icon: React.ReactNode, selected?: boolean, to: string }) {
    return (
        <Link className={clsx(classes.item, selected && classes.active)} to={to}>
            <Group align="center" gap="md">
                {icon}
                {text}
            </Group>
        </Link>
    );
}