import LeftNav from "./Components/LeftNav";
import logo from "./Assets/icon-base-cropped-no-background.png";
import Router from "./Router";
import { AppShell, Burger, Button, Group } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useAuthorization } from "./Context/AuthorizationProvider";
import { useDisclosure } from "@mantine/hooks";
import { useEffect } from "react";
import { useLocation } from "react-router";

const queryClient = new QueryClient();

export default function App() {
    const { auth } = useAuthorization();
    const [opened, { toggle, close }] = useDisclosure();
    const location = useLocation();

    useEffect(close, [location, close]);

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AppShell
                header={{ height: 60 }}
                navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
                padding="md"
            >
                <AppShell.Header>
                    <Group h="100%" px="md" justify="space-between">
                        <Burger
                            opened={opened}
                            onClick={toggle}
                            hiddenFrom="md"
                            size="sm"
                        />
                        <img src={logo} alt="Logo" height="40" width="40" />
                        <Button variant="light" onClick={() => auth.logout()}>Log out</Button>
                    </Group>
                </AppShell.Header>
                <LeftNav />
                <AppShell.Main>
                    <Router />
                </AppShell.Main>
            </AppShell>
        </QueryClientProvider>
    );
}