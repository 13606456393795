import Account from "./Pages/Account";
import Redirects from "./Pages/Redirects";
import { Navigate, Route, Routes } from "react-router";

export default function Router() {
    return (
        <Routes>
            <Route path="/redirects" element={<Redirects />} />
            <Route path="/account" element={<Account />} />
            <Route path="/*" element={<Navigate to="/redirects" />} />
        </Routes>
    );
}