import Auth from "./Auth";
import axios, { AxiosResponse } from "axios";
import { APITypes } from "./APITypes";

class API {
    public readonly endpoint: string;
    private readonly auth: Auth;
    public constructor(endpoint: string, auth: Auth) {
        this.endpoint = endpoint.replace(/\/$/ig, "");
        this.auth = auth;
    }

    protected async request(path: string, method: "post" | "get", body?: any) {
        const url = `${this.endpoint}${path}`;
        const headers = {
            "Authorization": `${this.auth.idToken}`
        };

        let response: AxiosResponse<any>;
        if (method === "post") {
            response = await axios.post(url, body, { headers });
        } else {
            response = await axios.get(url, { headers });
        }

        return response.data;
    }
}

export class DatabaseAPI extends API {
    public constructor(endpoint: string, auth: Auth) {
        super(endpoint, auth);
    }

    public async listRedirects(params: APITypes.Database.ListRedirectsParams): Promise<APITypes.Database.ListRedirectsResponse> {
        return await this.request("/list-redirects", "get");
    }

    public async createRedirect(params: APITypes.Database.CreateRedirectParams): Promise<APITypes.Database.CreateRedirectResponse> {
        return await this.request("/create-redirect", "post", params);
    }

    public async deleteRedirect(params: APITypes.Database.DeleteRedirectParams): Promise<APITypes.Database.DeleteRedirectResponse> {
        return await this.request("/delete-redirect", "post", params);
    }

    public async checkPathnameAvailability(params: APITypes.Database.PathnameAvailableParams): Promise<APITypes.Database.PathnameAvailableResponse> {
        return await this.request("/pathname-available", "post", params);
    }
}